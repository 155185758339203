import React from 'react';
import {withRouter} from 'react-router-dom';
import {Col, Container, Row} from 'reactstrap';
import Parallax from 'parallax-js';
//import {Waypoint} from 'react-waypoint';
import ReactGA from 'react-ga';
import artistSvg from './svg/artist.svg'
import scientistSvg from './svg/scientist.svg'
import heroSvg from './svg/superhero.svg'
import './Home.scss';

class Home extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isLoading: false,
            view: 'loading',
        };
        this.myRef = React.createRef();
        this.scrollY = window.scrollY
        this.handleScroll = this.handleScroll.bind(this);
    }

    componentDidMount() {
        ReactGA.pageview('/Home');
        var scene = document.getElementById('scene');
        this.isLooping = true;
        this.parallax = new Parallax(scene);

        if (window.addEventListener) {
            window.addEventListener('scroll', this.handleScroll, true);
        } else {
            window.attachEvent('onScroll', this.handleScroll, true);
        }

    }

    componentWillUnmount() {
        this.isLooping = false;
        if (window.addEventListener) {
            window.removeEventListener('scroll', this.handleScroll, true);
        } else {
            window.removeEvent('onScroll', this.handleScroll, true);
        }
    };

    handleClick(param, e) {
    }

    /**
     *
     * @param {*} scrollDir
     * @param {*} scrollPos
     * @param {*} outcome
     * @param {*} start
     * @param {*} end
     */
    fade(scrollDir, scrollPos, outcome, start, end, maxOpacity) {

        var maxO;
        maxO = maxOpacity === undefined ? maxO = 1 : maxO = maxOpacity
        //console.log(scrollDir, scrollPos, outcome, start, end)
        // ((input - min) * 100) / (max - min)
        var perc = ((scrollPos - start) * 100) / (end - start);

        const opac = perc / 100

        if (scrollDir === "down") {
            if (outcome === "down") {
                return maxO - opac
            } else {
                return opac
            }
        }

        if (scrollDir === "up") {
            if (outcome === "down") {
                return maxO - opac
            } else {
                return opac
            }
        }
    }

    /**
     *
     * @param {*} scrollDir
     * @param {*} scrollPos
     * @param {*} outcome
     * @param {*} rangeStart
     * @param {*} rangeEnd
     * @param {*} moveStart
     * @param {*} moveEnd
     */

    slide(scrollDir, scrollPos, outcome, rangeStart, rangeEnd, moveStart, moveEnd) {

        // ((input - min) * 100) / (max - min)

        var perc = (scrollPos - rangeStart) / (rangeEnd - rangeStart)
        var curpos = (moveEnd - moveStart) * (perc)

        if (scrollDir === "down") {
            if (outcome === "down") {
                return curpos + "px"
            } else {

                if (curpos < 0) {
                    curpos = curpos * -1
                }
                return moveEnd - curpos + "px"
            }
        }

        if (scrollDir === "up") {
            if (outcome === "down") {
                return curpos + "px"
            } else {
                return moveEnd - curpos + "px"
            }
        }
    }

    handleScroll(e) {

        var scrollDir = "down"
        this.scrollY <= window.scrollY ? scrollDir = "down" : scrollDir = "up"
        this.scrollY = window.scrollY //Don't get confused by what's scrolling - It's not the window

        const home = document.getElementById('home')
        const palette = document.getElementById('palette')
        const spinner = document.getElementById('spinner')
        const gridPaper = document.getElementById('gridPaperHolder')
        const tagDev = document.getElementById('tagDev')
        const tagInt = document.getElementById('tagInt')
        const tagDesign = document.getElementById('tagDesign')
        const devText = document.getElementById('devText')
        const devBlock = document.getElementById('devBlock')
        const artist = document.getElementById('artistHolder')
        const scientist = document.getElementById('scientistHolder')
        const hero = document.getElementById('heroHolder')
        const market = document.getElementById('marketHolder')


        // clean up elements when arriving top of page
        if (window.scrollY < 100) {
            home.style.opacity = 1;
            spinner.style.opacity = 0;
            tagDev.classList.remove('font-bold')
            tagInt.classList.remove('font-bold')
            tagDesign.classList.remove('font-bold')
            devBlock.style.width = 0;
            devBlock.style.left = -50;
            devText.style.opacity = 0;

            // --- full clean up for rapid scroll ---
            // clean up red / artist
            artist.style.height = 0;
            artist.getElementsByClassName('character-icon')[0].style.opacity = 0;
            artist.getElementsByClassName('site-text')[0].style.opacity = 0;
            market.style.opacity = 0;

            // clean up green/scientist
            scientist.style.height = 0;
            scientist.getElementsByClassName('character-icon')[0].style.opacity = 0;
            scientist.getElementsByClassName('site-text')[0].style.opacity = 0;

            // clean up blue/hero
            hero.style.height = 0;
            hero.getElementsByClassName('character-icon')[0].style.opacity = 0;
            hero.getElementsByClassName('site-text')[0].style.opacity = 0;
        }

        // development
        if (window.scrollY > 100 && window.scrollY < 500) {

            // scrollDir, scrollPos, outcome, start, end
            home.style.opacity = this.fade(scrollDir, window.scrollY, "down", 100, 500)
            spinner.style.opacity = this.fade(scrollDir, window.scrollY, "up", 200, 500)
            gridPaper.style.opacity = this.fade(scrollDir, window.scrollY, "up", 100, 500)

            devText.style.opacity = this.fade(scrollDir, window.scrollY, "up", 400, 500)
            devBlock.style.width = this.slide(scrollDir, window.scrollY, "down", 100, 400, -100, 1100)

            tagDev.classList.add('font-bold');
            tagInt.classList.remove('font-bold');
            tagDesign.classList.remove('font-bold');
        }


        if (window.scrollY > 500 && window.scrollY < 800) {

            home.style.opacity = 0;
            spinner.style.opacity = this.fade(scrollDir, window.scrollY, "down", 500, 800)
            // gridPaper.style.opacity = this.fade(scrollDir, window.scrollY, "down", 500, 800)
            devBlock.style.opacity = this.fade(scrollDir, window.scrollY, "down", 500, 800, .55)
            devText.style.opacity = this.fade(scrollDir, window.scrollY, "down", 600, 800)

            // clean up red / artist
            artist.style.height = 0;
            artist.getElementsByClassName('character-icon')[0].style.opacity = 0;
            artist.getElementsByClassName('site-text')[0].style.opacity = 0;
            market.style.opacity = 0;
        }

        // ----------------------------------
        // integration
        if (window.scrollY > 900 && window.scrollY < 1100) {
            home.style.opacity = 0;
            palette.style.opacity = 0;
            gridPaper.style.opacity = 0;
            spinner.style.opacity = 0;
            devBlock.style.opacity = 0;
            devText.style.opacity = 0;

            tagDev.classList.remove('font-bold');
            tagInt.classList.add('font-bold');
            tagDesign.classList.remove('font-bold');

            market.style.opacity = this.fade(scrollDir, window.scrollY, "up", 900, 1300)
            artist.style.height = this.slide(scrollDir, window.scrollY, "down", 900, 1100, -30, 700)
        }

        // fade up artist
        if (window.scrollY > 1100 && window.scrollY < 1300) {
            artist.getElementsByClassName('character-icon')[0].style.opacity = this.fade(scrollDir, window.scrollY, "up", 1100, 1300)
            artist.getElementsByClassName('site-text')[0].style.opacity = this.fade(scrollDir, window.scrollY, "up", 1100, 1300)

            // clean up green/scientist
            scientist.style.height = 0;
            scientist.getElementsByClassName('character-icon')[0].style.opacity = 0;
            scientist.getElementsByClassName('site-text')[0].style.opacity = 0;
        }

        // enter green
        if (window.scrollY > 1300 && window.scrollY < 1500) {

            // prop up red bar
            artist.style.height = 700;
            artist.getElementsByClassName('character-icon')[0].style.opacity = 1;
            artist.getElementsByClassName('site-text')[0].style.opacity = 1;
            scientist.style.height = this.slide(scrollDir, window.scrollY, "down", 1300, 1500, -30, 700)
        }

        // fade up scientist
        if (window.scrollY > 1500 && window.scrollY < 1700) {
            scientist.getElementsByClassName('character-icon')[0].style.opacity = this.fade(scrollDir, window.scrollY, "up", 1500, 1700)
            scientist.getElementsByClassName('site-text')[0].style.opacity = this.fade(scrollDir, window.scrollY, "up", 1500, 1700)

            // clean up blue/hero
            hero.style.height = 0;
            hero.getElementsByClassName('character-icon')[0].style.opacity = 0;
            hero.getElementsByClassName('site-text')[0].style.opacity = 0;
        }

        // enter blue
        if (window.scrollY > 1700 && window.scrollY < 1900) {
            // prop up green bar
            scientist.style.height = 700;
            scientist.getElementsByClassName('character-icon')[0].style.opacity = 1;
            scientist.getElementsByClassName('site-text')[0].style.opacity = 1;
            hero.style.height = this.slide(scrollDir, window.scrollY, "down", 1700, 1900, -30, 700)
        }


        // --- hero
        // fade up hero
        if (window.scrollY > 1900 && window.scrollY < 2100) {
            hero.getElementsByClassName('character-icon')[0].style.opacity = this.fade(scrollDir, window.scrollY, "up", 1900, 2100)
            hero.getElementsByClassName('site-text')[0].style.opacity = this.fade(scrollDir, window.scrollY, "up", 1900, 2100)
        }


        // design
        if (window.scrollY > 2200 && window.scrollY < 2300) {
            home.style.opacity = 0;
            spinner.style.opacity = 0;
            palette.style.opacity = this.fade(scrollDir, window.scrollY, "up", 2200, 2600)
        }

        if (window.scrollY > 2200 && window.scrollY < 2600) {
            tagDev.classList.remove('font-bold');
            tagInt.classList.remove('font-bold');
            tagDesign.classList.add('font-bold');
        }
    }


    render() {

        return (<>
                <div className="homeWrap">
                    <Container>
                        <Row>
                            <Col xs={12} md={12} lg={12}>
                                <div className="outerWrap">
                                    <div id="home" className="homepage-bg content-slide" ref={this.myRef}></div>
                                    <div id="gridPaperHolder">
                                        <div className=" grid-paper-bg content-slide"></div>
                                    </div>
                                    <div className="pcover">
                                        <ul id="scene" className="scene fill">
                                            <li className="layer expand-width" data-depth="0.15">
                                                <div className="umbrella">
                                                    <img
                                                        src={process.env.PUBLIC_URL + "../img/pete_w_umbrella_transparent.png"}
                                                        alt="" className="umbrella-man"/>
                                                </div>
                                            </li>
                                        </ul>
                                    </div>
                                    <div id="palette" className="palette" style={{opacity: 0}}>
                                        <img src={process.env.PUBLIC_URL + "../img/colorwheel.png"} alt=""/>
                                    </div>

                                    <div id="spinner" className="spinner" style={{opacity: 0}}>

                                        <div className="ring1">
                                            <img src={process.env.PUBLIC_URL + "../img/ring/ring1.png"} alt=""
                                                 className="ring"/>
                                        </div>
                                        <div className="ring2">
                                            <img src={process.env.PUBLIC_URL + "../img/ring/ring2.png"}
                                                 alt="" className="ring"/>
                                        </div>
                                        <div className="ring3">
                                            <img src={process.env.PUBLIC_URL + "../img/ring/ring3.png"} alt=""
                                                 className="ring"/>
                                        </div>
                                        <div className="ring-react">
                                            <img src={process.env.PUBLIC_URL + "../img/React-icon.svg"} alt=""
                                                 className="ring"/>
                                        </div>
                                    </div>

                                    <div id="devBlock"></div>
                                    <div id="devText" className="font-main medium-text">
                                        Pete Friedman is a Seattle area web developer with interests in photography,
                                        engineering, and immersive multimedia experiences.
                                    </div>


                                    {  /** integration  */}

                                    <div id="marketHolder">
                                    </div>
                                    <div id="artistHolder">
                                        <div className="character-icon">
                                            <img src={artistSvg} alt="artist"/>
                                        </div>
                                        <div className="font-main site-text off-white padded-1">
                                            On the front end, Pete likes the composition based approach of components
                                            with modern frameworks like Vue and React.
                                            <br/><br/>
                                            An avid photographer, Pete enjoys montage and web production with the Adobe
                                            Creative Suite.

                                        </div>
                                    </div>

                                    <div id="scientistHolder">
                                        <div className="character-icon">
                                            <img src={scientistSvg} alt="scientist"/>
                                        </div>

                                        <div className="font-main site-text off-white padded-1">

                                            On the back end and middle tier, Pete is developing projects that leverage
                                            tools offered by PAAS vendors like AWS, Netlify and MongoDB Atlas.
                                            <br/><br/>
                                            Pete has broad experience developing web and mobile applications in
                                            JavaScript, PHP, C#, Kotlin, and Ruby.

                                        </div>

                                    </div>
                                    <div id="heroHolder">
                                        <div className="character-icon">
                                            <img src={heroSvg} alt="super hero"/>
                                        </div>

                                        <div className="font-main site-text off-white padded-1">
                                            Pete enjoys a long history of solving problems for happy clients. Whether as
                                            an individual contributor or leading a development team, Pete knows how to
                                            meet goals and keep it fun and on budget.
                                        </div>
                                    </div>


                                </div>


                            </Col>
                        </Row>

                        <Row noGutters={true} className="botbar">
                            <Col xs={12} md={9} lg={9}>
                                <div id="tagline" className="font-semi-bold off-white">
                                    <span id="tagDev" className="font-semi-bold off-white">Development</span> &middot;
                                    <span id="tagInt" className="font-semi-bold off-white">Integration</span> &middot;
                                    <span id="tagDesign" className="font-semi-bold off-white">Design</span>
                                </div>
                            </Col>
                            <Col xs={12} md={3} lg={3}>
                                <div id="socials">
                                    <div className="inline font-main off-white padded-lr-space">
                                        <a href="https://www.linkedin.com/in/pete-friedman206/">
                                            <img alt="Link to Pete's LinkedIn" src="img/icon-linkedin.png"
                                                 className="social-icon"/>
                                        </a>
                                    </div>
                                    <div className="inline font-main off-white padded-lr-space">
                                        <a href="https://www.facebook.com/petefriedman206">
                                            <img alt="Link to Pete's Facebook" src="img/icon-facebook.png"
                                                 className="social-icon"/>
                                        </a>
                                    </div>
                                    <div className="inline font-main off-white padded-lr-space">
                                        <a href="https://twitter.com/pete_friedman">
                                            <img alt="Link to Pete's Twitter" src="img/icon-twitter.png"
                                                 className="social-icon"/>
                                        </a>
                                    </div>
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </div>

                <div className="scroller-div"></div>
            </>
        );
    }
}

export default withRouter(Home);
