import React from 'react';
import {withRouter} from 'react-router-dom';
import {Col, Container, Row} from 'reactstrap';
import ReactGA from 'react-ga';
import './Home.scss';

class NotFound extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      view: 'loading'
    };

  }

  componentDidMount() {
    ReactGA.pageview('/NotFound');
  }

  componentWillUnmount() {

  };

  handleClick(param, e) {}


  render() {
    return (<Container>
      <Row>
        <Col xs={12} md={12} lg={12}>
          <div className="rel">
          <div className="abs" id="hero404">
              <img src={process.env.PUBLIC_URL + "/img/404-desert.jpg"} alt="" className="r-img"/>
          </div>
          <div className="abs font-semi-bold off-white" id="tagline404" >
          <span className="big-text">404</span>Page not found.
          </div>

          </div>

        </Col>
      </Row>
      <Row noGutters={true} className="botbar">
        <Col xs={12} md={9} lg={9}>
              </Col>
      </Row>
    </Container>);
  }
}
export default withRouter(NotFound);
