import React from 'react';
import {withRouter} from 'react-router-dom';
import {Container, Row} from 'reactstrap';
import './Contact.scss';

class Contact extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            isLoading: false,
            view: 'loading'
        };
    }

    componentDidMount() {
    }

    beginTransport(iter) {
    }

    componentWillUnmount() {
    };

    endTransport() {
    }

    exitHandler(obj) {
        this.setState({view: 'grid'});
    }

    render() {
        return (<Container>
            <Row className="no-gutters"></Row>
        </Container>)
    }
}

export default withRouter(Contact);
