import React from 'react';
import PropTypes from "prop-types";
import {Link, NavLink, withRouter} from 'react-router-dom';
import {Col, Container, Row} from 'reactstrap';
import './NavHeader.scss';

//import NavLink from './NavLink';

class NavHeader extends React.Component {

  static propTypes = {
    match: PropTypes.object.isRequired,
    location: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired
  };

  componentDidUpdate(prevProps, prevState) {
    //console.log("navheader didupdate" + JSON.stringify(this.state));
  }

  handleHeaderClick(param, event) {
    this.setState({nav: param})
  }

  render() {
    return (<>
      <div className="fixed-nav-bar">
      <Container className="off-black-bg">
      <Row>
        <Col xs={12} md={6} lg={6}>
              <div className="site-title-bar">

                <div className="site-title">
                  
                  <Link to="/" >
                    <span className="font-semi-bold off-white">Pete Friedman</span>
                  
                  </Link>
                </div>
          </div>
        </Col>
        <Col xs={12} md={6} lg={6}>
          <div className="font-main nav-bar">
            <NavLink className="site-nav" activeClassName="active" exact="true" to="/">Home</NavLink>
            <NavLink className="site-nav" activeClassName="active" to="/Work/">Work</NavLink>
            <NavLink className="site-nav" activeClassName="active" to="/Gallery/">Gallery</NavLink>
          </div>
        </Col>
      </Row>
        </Container>
      </div>
    </>);
  }
}

export default withRouter(NavHeader);
