import React from 'react';
import {withRouter} from 'react-router-dom';
import {Col, Container, Row} from 'reactstrap';
import GalleryDetail from '../components/GalleryDetail.jsx';
import './Gallery.scss';
import ReactGA from 'react-ga';
import {toJS} from 'mobx';
import {inject, observer} from 'mobx-react';
import {FULFILLED, PENDING, REJECTED} from 'mobx-utils';

export default @inject('galleryStore') @observer @withRouter class Gallery extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            imgInfo: {},
            viewMode: 'grid',
            loaded: false
        }
        this.galData = [];
        this.mediaIds = [];
        this.galDetail = {};
        this.backToGridHandler = this.backToGridHandler.bind(this);
        this.handleGridClick = this.handleGridClick.bind(this);
     
    }

    async componentDidMount() {
        ReactGA.pageview('/Gallery');
        await this.props.galleryStore.fetchGalleries();
        this.setState({ loaded: 'true' });
    }

    backToGridHandler(obj) {
        this.setState({ viewMode: 'grid' });
    }

    handleGridClick(gal, e) {
        let obj = toJS(gal);
        this.setState({ viewMode: 'gallery', galDetail: obj });
    }

    whenAnimationEnd(element, callback) {
        element.addEventListener('animationend', callback, false);
        element.addEventListener('webkitAnimationEnd', callback, false);
        element.addEventListener('oanimationend', callback, false);
        element.addEventListener('MSAnimationEnd', callback, false);
    }


    animatePreview(e) {
        var el = e.currentTarget;
        var q = el.firstChild.firstChild;
        this.whenAnimationEnd(q,this.endAnimatePreview(e))
        q.style.animationPlayState = "running";
    }

    endAnimatePreview(e) {

        var el = e.currentTarget;
        var q = el.firstChild.firstChild;
  
        if (q !== null) {
            if ('classList' in q) {
                if (q.classList.contains("galAnimation")) {

                    q.style.animationPlayState = "paused";
                    q.classList.remove("galAnimation");
                    q.style.animation = 'none';
                    q.style.animation = null;
                    void q.offsetWidth;
                 
                    window.setTimeout(() => {
                        q.classList.add("galAnimation");

                        q.style.animation = 'shine 2s infinite forwards';
                        q.style.animationPlayState = "paused";
                   }, 30);
                }
            }
        }
    }

    render() {
        switch (this.state.viewMode) {

            case "grid":

                if (this.props.galleryStore.state === REJECTED) {

                }
                if (this.props.galleryStore.state === PENDING) {
                    return null
                }
                if (this.props.galleryStore.state === FULFILLED) {

                    this.items = this.props.galleryStore.galleryData.map((gal, key) => <React.Fragment key={key} >

                        <div className="gal-icon" onClick={
                            (e) => this.handleGridClick(gal, e)}
                            onMouseLeave={(e) => this.endAnimatePreview(e)} onMouseEnter={(e) => this.animatePreview(e)}>
                            <div className="galImageTop">
                                <div className="galAnimation"></div>
                                <div className="galImgWrap">< img alt="By Pete Friedman"
                                    src={process.env.PUBLIC_URL + '/img/' + gal.previewImg}
                                    className="galPreview" />
                                </div>
                              
                            </div>
                            <div className="gal-info">
                                <button className="site-nav" > {gal.name}
                                    <br />
                                    {gal.desc} </button>
                            </div>

                        </div>

                    </React.Fragment>);

                    return (<Container className="gallery dark-bg font-main off-white">

                        <Row>

                            <Col className="previewRow" xs={12}
                                md={12}
                                lg={12} >

                                <div id="galGrid" > {this.items} </div>
                            </Col>
                        </Row>
                    </Container>)
                }
                break;

            case "gallery":

                return (< Container >

                    <Row className="no-gutters" >

                        <Col xs={12}
                            md={12}
                            lg={12} >

                            <div id="galContent" >
                                <GalleryDetail backToGridHandler={this.backToGridHandler}
                                    galDetail={this.state.galDetail} />
                            </div>
                        </Col>
                    </Row>
                </Container>);
             

            default:
        }
    }
}
