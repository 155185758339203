import React from 'react';
import {withRouter} from 'react-router-dom';
import {Col, Container, Row} from 'reactstrap';
import './Work.scss';

class WorkFilters extends React.Component {


    clearActiveButtons = (nodes, filterBy, filterItem) => {


        if (nodes.length > -1) {
            for (var n of nodes) {
                n.classList.remove('active');
            }
        }
    }

    /* get unique objects from Object Array */
    getUnique(arr, comp) {
        const unique = arr.map(e => e[comp])
            // store the keys of the unique objects
            .map((e, i, final) => final.indexOf(e) === i && i)
            // eliminate the dead keys & store unique objects
            .filter(e => arr[e]).map(e => arr[e]);
        return unique;
    }

    componentDidUpdate() {
        let techNodes = document.querySelectorAll('Button.tech-btn');
        let clientNodes = document.querySelectorAll('Button.client-btn');
        let sectionNodes = document.querySelectorAll('Button.section-btn');

        this.clearActiveButtons(sectionNodes);

        this.clearActiveButtons(techNodes);
        this.clearActiveButtons(clientNodes);

        switch (this.props.filterBy) {

            case "tech":
                document.getElementById('techGroup').classList.remove('no-display');
                document.getElementById('clientGroup').classList.add('no-display');
                document.getElementById('tech').classList.add('active');
                break;
            case "client":
                document.getElementById('techGroup').classList.add('no-display');
                document.getElementById('clientGroup').classList.remove('no-display');
                document.getElementById('client').classList.add('active');
                break;
            default:
                document.getElementById('techGroup').classList.add('no-display');
                document.getElementById('clientGroup').classList.add('no-display');
                break;
        }
        if (this.props.filterItem !== null && this.props.filterItem !== 'clear') {
            document.getElementById(this.props.filterItem).classList.add('active');
        }
        if (this.props.filterBy !== null && this.props.filterBy !== 'clear') {

            document.getElementById(this.props.filterBy).classList.add('active');
        }
        if (this.props.filterBy !== null && this.props.filterBy === 'clear') {

            document.getElementById('reset').classList.remove('active');
            document.getElementById('reset').blur();
        }
    }

    render() {


        // get unique list of clients for drop down
        var uniqueClients = this.getUnique(this.props.workData, "client");
        const clientBtnGroup = uniqueClients.map((item, key) => <button className="btn btn-primary client-btn sub-group"
                                                                        id={item.clientId} key={item._id}
                                                                        onClick={() => this.props.parentHandler('client', item.clientId)}>{item.client}</button>)

        // use for-of to destructure tech array in each element of workData
        let techArr = [];
        let t = [];
        this.props.workData.forEach(function (value) {
            for (t of value.tech) {
                techArr.push(t);
            }
        })
        // get unique list of tech
        const uniqueTech = [...new Set(techArr)];
        const techBtnGroup = uniqueTech.map((item, key) => <button className="btn btn-primary tech-btn sub-group"
                                                                   id={item} key={key}
                                                                   onClick={() => this.props.parentHandler('tech', item)}>
            {item}</button>)

        return (<Container fluid={true} className="light-bg">
            <Row noGutters={true}>
                <Col>
                    <div role="group" className="btn-group flex-start flex-wrap ">
                        <button id="client" className="btn btn-primary section-btn"
                                onClick={() => this.props.parentHandler('client', 'clear')}>Filter by Client
                        </button>
                        <button id="tech" className="btn btn-primary section-btn"
                                onClick={() => this.props.parentHandler('tech', 'clear')}>Filter by Tech
                        </button>
                        <button id="reset" className="btn btn-primary section-btn"
                                onClick={() => this.props.parentHandler('clear', 'clear')}>Reset Filters
                        </button>
                    </div>
                    <form id="filtersForm"></form>
                </Col>
            </Row>
            <Row noGutters={true}>
                <Col>
                    <div id="clientGroup" role="group" className="btn-group small-text flex-wrap no-display">
                        {clientBtnGroup}
                    </div>

                    <div id="techGroup" role="group" className="btn-group small-text flex-wrap no-display">
                        {techBtnGroup}
                    </div>
                </Col>
            </Row>
        </Container>)
    }
}

export default withRouter(WorkFilters);
