import React, {Component} from 'react';
import {BrowserRouter as Router, Route, Switch} from 'react-router-dom';
import {Provider} from 'mobx-react';
import GalleryStore from './mobxStore/GalleryStore';
import NavHeader from './components/NavHeader.jsx';
import Home from './components/Home.jsx';
import Gallery from './components/Gallery.jsx';
import GalleryDetail from './components/GalleryDetail.jsx';
import Work from './components/Work.jsx';
import Contact from './components/Contact.jsx';
import NotFound from './components/NotFound.jsx';
import ScrollToTop from './components/ScrollToTop.jsx';

import 'typeface-montserrat';
import 'bootstrap/dist/css/bootstrap.css';
import './components/App.scss';
import './components/Home.scss';
import dotenv from 'dotenv';

dotenv.config();

const galleryStore = new GalleryStore();


class App extends Component {

    render() {
        return (<Provider galleryStore={galleryStore}>
            <Router>

                <ScrollToTop>
                    <NavHeader/>
                    <Switch>
                        <Route exact={true} path="/" component={Home}/>
                        <Route path="/work" component={Work}/>
                        <Route exact path="/Gallery" component={Gallery}/>
                        <Route exact path={'/GalleryDetail/:id'} component={GalleryDetail}/>
                        <Route path="/contact" component={Contact}/>
                        <Route component={NotFound}/>
                    </Switch>
                </ScrollToTop>
            </Router>

        </Provider>)
    }
}

export default App;
