import React from 'react';
import {withRouter} from 'react-router-dom';
import {Col, Container, Row} from 'reactstrap';
import WorkFilters from './WorkFilters.jsx';
import PropTypes from "prop-types";
import './Work.scss';
import ReactGA from 'react-ga';

class Work extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            dataLoaded: false,
            view: 'loading',
            filterBy: 'clear',
            filterItem: 'clear',
            sortDir: 'desc',
            workData: []
        };
        this.items = [];
        this.filterBar = {};
        this.filterBarHandler = this.filterBarHandler.bind(this)
        this.exitHandler = this.exitHandler.bind(this)
    }

    static propTypes = {
        match: PropTypes.object.isRequired,
        location: PropTypes.object.isRequired,
        history: PropTypes.object.isRequired
    };

    componentDidMount() {
        ReactGA.pageview('/Work');
        fetch(process.env.REACT_APP_EXPRESS_SERVICE + '/work', {mode: 'cors'}).then(response => response.json()).then(data => this.onDataLoaded({
            data: data,
            view: 'grid'
        }));
    }

    onDataLoaded(obj) {
        // sort all by date descending as init state
        obj.data.sort((a, b) => new Date(b.dateMade) - new Date(a.dateMade));
        this.setState({dataLoaded: true, view: obj.view, workData: obj.data});
    }

    // viewstate filterBy:
    // clear - reset form
    // byClient(val)
    // byTech(val)

    filterBarHandler(filterBy, filterItem) {

        this.props.history.push({
            pathname: '/Work',
            state: {
                filterBy: filterBy,
                filterItem: filterItem
            }
        })


        this.setState({filterBy: filterBy, filterItem: filterItem});
    }

    componentDidUpdate() {
        window.onpopstate = (e) => {
            this.setState({
                filterBy: this.props.location.state.filterBy,
                filterItem: this.props.location.state.filterItem
            });
        }
    }

    exitHandler(obj) {

    }

    itemHasLink = (item) => {

        let link;
        let l = item.links.length;
        l > 0
            ? link = (
                <a className="font-main dark-text" rel="noopener noreferrer" target="_blank" href={item.links[0]}>View the
                    work</a>)
            : link = '';
        return (link);
    }

    render() {
        var that = this;
        this.items = this.state.workData;

        switch (this.state.filterBy) {

            case "selectClient":
            case "client":
                this.items = this.state.workData.filter(function (item) {
                    if (that.state.filterItem === 'clear') {
                        return that.state.workData;
                    } else {
                        return item.clientId === that.state.filterItem
                    }
                });
                break;

            case "selectTech":
            case "tech":
                this.items = this.state.workData.filter(function (item) {
                    if (that.state.filterItem === 'clear') {
                        return that.state.workData;
                    } else {
                        return item.tech.includes(that.state.filterItem);
                    }
                });
                break;

            default:
                this.items = this.state.workData;
                break;
        }


        this.items = this.items.map((item, key) => <Row noGutters={true} key={key}>
            <Col xs={12} md={6} lg={6}>
                <div className="work-desc-holder">
                    <div className="work-desc">
                        <span className="bold-title">{item.name}</span><br/>
                        <span className="site-text-light">{item.desc}</span>
                        <br/><br/> {(this.itemHasLink(item))}
                    </div>
                </div>
            </Col>
            <Col xs={12} md={6} lg={6}>
                <div className="work-holder">
                    <div className="work-content">
                        <img src={process.env.PUBLIC_URL + '/work/' + item.filePath} alt={item.name}
                             className="r-img work-image"/>
                    </div>
                </div>
            </Col>
        </Row>);


        this.state.dataLoaded === true
            ? this.filterBar = <WorkFilters parentHandler={this.filterBarHandler}
                                            workData={this.state.workData}
                                            filterBy={this.state.filterBy}
                                            filterItem={this.state.filterItem}
                                            sortDir={this.state.sortDir} className="padded-1"/>
            : this.filterBar = <React.Fragment>
                <Col xs={12}>
                    <p className="center">loading.</p>
                </Col>
            </React.Fragment>

        return (<Container fluid={true} className="light-bg font-main dark-text work-page-container">
            <Row noGutters={true} className="work-header">
                {this.filterBar}
            </Row>
            {this.items}

        </Container>)
    }
}

export default withRouter(Work);
