import React from 'react';
import {withRouter} from 'react-router-dom';
import {Col, Container, Row} from 'reactstrap';
import {Swipeable} from 'react-swipeable';
import './Gallery.scss';
import './GalleryDetail.scss';
import GalleryNav from '../components/GalleryNav.jsx';
import ReactGA from 'react-ga';
import {inject, observer} from 'mobx-react';
import {toJS} from 'mobx';
import {FULFILLED, PENDING, REJECTED} from 'mobx-utils'

export default @inject('galleryStore') @observer @withRouter
class GalleryDetail extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            galIndex: 0,
            imgInfo: {},
            viewMode: 'grid'
        }
        this.mediaIds = [];
        this.galDetail = this.props.galDetail;
        this.handleNavClick = this.handleNavClick.bind(this);

    }

    componentDidMount() {
        this.loadData();
    }

    componentDidUpdate() {
        let imgHolder = document.getElementById('currentImage');
        imgHolder.classList.remove('no-display');
    }


    async loadData() {

        await this.props.galleryStore.fetchGalleryById(this.props.galDetail._id);
        let currentGallery = toJS(this.props.galleryStore.currentGallery);
        this.mediaIds = currentGallery.items;
        this.loadMedia(this.mediaIds[0], 0);
    }

    async loadMedia(mediaId, index, dir) {
        let that = this;
        fetch(process.env.REACT_APP_EXPRESS_SERVICE + '/media/' + mediaId, {mode: 'cors'}).then((resp) => resp.json()).then(function (obj) {
            let img = document.getElementById('activeImage');
            if (img) {
                //  img.classList.remove('swipe-left', 'swipe-right');
            }
            ReactGA.event({category: 'Galleries', action: 'galleryImageView', label: obj.name});
            that.setState(function (prevState, props) {
                return {imgInfo: obj, galIndex: index}
            });
        });
    }

    /**
     * passed as navClickHandler
     */
    async handleNavClick(index, dir) {

        let imgHolder = document.getElementById('currentImage');

        var i;
        var len = (this.mediaIds.length) - 1;
        //dir == undefined?
        index > len ?
            i = 0 :
            i = index;
        index < 0 ?
            i = len :
            i = index;
        if (i > len) {
            i = 0;
        }
        //console.log(index + " of " + this.mediaIds.length + " items");
        const animationEndHandler = () => {
            imgHolder.removeEventListener('animationend', animationEndHandler);
            this.loadMedia(this.mediaIds[i], i, dir);
            imgHolder.classList.add('no-display');
            imgHolder.classList.remove('swipe-left', 'swipe-right');
        };

        if (imgHolder != null) {
            if (dir !== 'none') {
                void imgHolder.offsetWidth; // key reset hack for repeat animation
                imgHolder.classList.add('swipe-' + dir);

            }
            imgHolder.addEventListener('animationend', animationEndHandler);

        } else {

        }

    }

    // aspect ratio fix
    imageFixer(target) {
        let img = toJS(target.currentTarget);
        let p = document.getElementById(img.id).parentElement;
        if (img.width > img.height) {

            p.classList.remove('gal-image');
            p.classList.add('gal-image-horz');
        } else {
            p.classList.add('gal-image');
            p.classList.remove('gal-image-horz');
        }
    }

    render() {
        if (this.props.galleryStore.state === PENDING) {
            return null
        }
        if (this.props.galleryStore.state === FULFILLED) {
            const config = {
                onSwipedLeft: () => this.handleNavClick(this.state.galIndex + 1, 'right'),
                onSwipedRight: () => this.handleNavClick(this.state.galIndex - 1, 'left'),
                preventDefaultTouchmoveEvent: true,
                trackMouse: true,
                trackTouch: true,
                rotationAngle: 20
            };

            return (<Container className="gallery-detail font-main off-white">
                <
                    GalleryNav navClickHandler={this.handleNavClick}
                               backToGridHandler={this.props.backToGridHandler}
                               galArr={this.mediaIds}
                               galIndex={this.state.galIndex}
                               galName={this.galDetail.name}
                /> <
                Row>

                <Col xs={1}> </Col>
                <Col xs={10}>

                    <div id="galContent">
                        <Swipeable {...config} >
                            <div className="gal-image"
                                 id="currentImage">
                                <img src={process.env.PUBLIC_URL + '/img/' + this.state.imgInfo.filePath}
                                     alt={this.state.imgInfo.name}
                                     id="activeImage"
                                     onLoad={this.imageFixer}/>
                            </div>
                            <div className="gal-info off-white center"> {this.state.imgInfo.name} </div>
                        </Swipeable></div>
                </Col>
                <Col xs={1}> </Col>
            </Row> </Container>)
        }
        if (this.props.galleryStore.state === REJECTED) {
        }
    }
}