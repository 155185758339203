import React from 'react';
//import { Link } from 'react-router-dom';
import {Col, Row} from 'reactstrap';
import './GalleryDetail.scss';

class GalleryNav extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            items: [],
            index: 0
        }
        this.galitems = {};
    }

    render() {
        var active = 'gal-nav-icon gal-nav-icon-active';
        var normal = 'gal-nav-icon ';
        var theItems = this.props.galArr;
        this.galitems = theItems.map((items, i) => < div key={i}
            className={
                i === this.props.galIndex ?
                    active : normal
            }
            onClick={
                (e) => this.props.navClickHandler(i, i >= this.props.galIndex ? 'left' : 'right')
            }> </div>)
        var prevItem = this.props.galIndex - 1
        var nextItem = this.props.galIndex + 1;

        //const { onClick } = this.props

        return (<Row className="gallery__nav no-gutters">

            <Col xs={12} >

                <div className="gallery__nav-row" >


                    <div className="gal-cell left"
                        onClick={
                            (e) => this.props.navClickHandler(prevItem, 'right')
                        }> <img alt="Next" src={'/img/forward-arrow.svg'}
                            className='gallery__arrow gallery__arrow-back'/> </div>  
                                <div className="gal-cell gal-btn" > {this.galitems} </div> 
                                    <div className="gal-cell right"
                                    onClick={
                                        (e) => this.props.navClickHandler(nextItem,'left')
                                    }> 
                                    <img alt="Prev" src={'/img/forward-arrow.svg'}
                                        className='gallery__arrow'/> 
                                        </div>
                        </div>
                            <div className="gallery__nav-row">

                                <div className="gal-cell gal-btn"
                                    onClick={this.props.backToGridHandler}>
                                    All Galleries <p> < i className="gal-arrow gal-arrow-right"> </i></p>
                                </div>
                                <div className="gal-cell gal-info center" > {this.props.galName}
                                </div>
                            </div> 
                        </Col>
                        </Row>)
                    }
                }
                export default GalleryNav;