import {action, configure, flow, observable,} from 'mobx';

import {FULFILLED, PENDING, REJECTED} from 'mobx-utils';

configure({
    enforceActions: 'observed'
});


class GalleryStore {

    @observable galleryData = []
    @observable currentGallery = []
    @observable state = PENDING

    constructor() {
        this.galleryData = []
        this.currentGallery = {}
        this.state = PENDING
    }

    @action.bound
    fetchGalleries = flow(function* fetchGalleries() {
        this.state = PENDING;
        try {
            yield fetch(process.env.REACT_APP_EXPRESS_SERVICE + '/gallery', {
                mode: 'cors'
            })
                .then(res => res.json())
                .then(data => {
                    this.galleryData = data;
                    this.state = FULFILLED;
                })
        } catch (e) {
            this.state = REJECTED
        }
    })

    @action.bound
    fetchGalleryById = flow(function* fetchGalleryById(id) {

        this.state = PENDING;
        try {
            yield fetch(process.env.REACT_APP_EXPRESS_SERVICE + '/gallery/' + id, {
                mode: 'cors'
            })
                .then(res => res.json())
                .then(data => {
                    this.currentGallery = data;
                    this.state = FULFILLED;
                });
        } catch (e) {

            this.state = REJECTED;
        }
    })
}


export default GalleryStore;
